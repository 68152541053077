@tailwind base;
@tailwind components;
@tailwind utilities;

.react-grid-item:hover{
z-index: 10;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
} 

.MuiDataGrid-root .MuiDataGrid-columnHeader {
  background-color: #ebe9e9;
  border-right: 3px solid black;

}

.MuiDataGrid-root .MuiDataGrid-columnHeader:last-child {
  border-right: 0px solid black;

}